import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from "../../components/layout/layout"
import Seonew from "../../components/seo-new"
import CTASection from "../../components/sections/cta-section"

const BlogPostTemplate = ({ data }) => {
  const doc = data.prismicBlogPost.data
  const imgSrc = getSrc(doc.featured_image)
  const imgFeatured = getImage(doc.featured_image)

  return (
    <Layout>
      <Seonew
        title={doc.title ? doc.title : null}
        image={imgSrc ? imgSrc : null}
      />
      <div className="bg-gray-100 w-full px-4 pt-40 pb-32">
        <div className="w-full max-w-screen-lg mx-auto">
          <h1 className="text-3xl md:text-5xl text-center text-gray-800 font-semibold leading-snug mt-6">{doc.title}</h1>
          <p className="text-center mt-8">{doc.date}</p>
        </div>
      </div>

      <div className='bg-white px-4 py-24'>
        <div className='w-full max-w-2xl mx-auto'>
          {imgFeatured
            ?
            <div className='mb-8'>
              <GatsbyImage
                alt={doc.featured_image.alt}
                image={imgFeatured}
                className="border"
              />
              <span className='block text-xs italic text-gray-500 text-center mt-2'>{doc.featured_image.alt}</span>
            </div>
            :
            null
          }

          <div>
            <RichText
              render={doc.content.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </div>
      </div>

      <CTASection />
    </Layout>
  )
}

export default withPrismicPreview(BlogPostTemplate)

export const pageTemplateQuery = graphql`
  query($id: String!) {
    prismicBlogPost(id: { eq: $id }) {
      _previewable
      uid
      data {
        title
        date(formatString: "MMMM Do YYYY", locale: "en-US")
        featured_image {
          alt
          gatsbyImageData(
            width: 800
          )
        }
        content {
          richText
        }
      }
    }
  }
`