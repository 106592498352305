import React from "react"
import { navigate } from '@reach/router'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const menuItems = "text-base text-white font-display font-semibold uppercase tracking-wider"

const HeaderNew = () => {
  return (
    <div className="fixed bg-black w-full p-4 z-50">

      <header className="w-full max-w-screen-xl mx-auto">
        <nav className="flex items-center justify-between mx-auto">
          <div className="flex items-center">
            <Link to="/">
              <StaticImage
                src="../../images/logos/fw-new-logo-green-for-dark-background.png"
                width="200"
                alt="fastweb.dev Logo"
              />
            </Link>
          </div>

          <ul className="hidden xl:flex flex-grow items-center justify-center flex-wrap space-x-12">
            <li>
              <button
                className={menuItems}
                onClick={event => {
                  event.preventDefault()
                  navigate("/#benefits")
                }}
              >
                Benefits
              </button>
            </li>

            <li>
              <button
                className={menuItems}
                onClick={event => {
                  event.preventDefault()
                  navigate("/#services")
                }}
              >
                Services
              </button>
            </li>

            <li>
              <button
                className={menuItems}
                onClick={event => {
                  event.preventDefault()
                  navigate("/#clients")
                }}
              >
                Clients
              </button>
            </li>

            <li>
              <button
                className={menuItems}
                onClick={event => {
                  event.preventDefault()
                  navigate("/#testimonials")
                }}
              >
                Testimonials
              </button>
            </li>

            <li className={menuItems}>
              <Link to="/blog">
                Blog
              </Link>
              </li>
          </ul>

          <div className="flex-shrink-0 md:w-48">
            <Link to="/contact">
              <button className="w-full bg-site-green hover:bg-site-green-100 text-white px-6 py-2 font-semibold uppercase tracking-wider">
                Hire Us
              </button>
            </Link>
          </div>
        </nav>
      </header>
    </div>
  )
}

export default HeaderNew