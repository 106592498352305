import React from "react"
import { Link } from "gatsby"

const CTASection = () => {
  return (
    <div className="bg-blue-200 w-full px-4 pt-24 pb-32">

      <div className="w-full max-w-screen-xl flex flex-col xl:flex-row items-center justify-center xl:justify-between mx-auto">
        <div className="w-full max-w-4xl">
          <h2 className="text-4xl md:text-6xl text-blue-900 text-center xl:text-left font-semibold mt-6">Questions?<br className="md:hidden" /> Let's Talk!</h2>
          <p className="text-lg xl:text-xl text-blue-900 text-center xl:text-left mt-6">We’ll help you decide whether Jamstack and Gatsby is right for your business, answer technical questions, help you choose the best approach for your website and more.</p>
        </div>

        <div className="flex-shrink-0 mt-12 xl:mt-0">
          <Link to="/contact">
            <button className='font-body text-xl bg-site-green border-2 border-site-green hover:border-site-green-100 hover:bg-site-green-100 text-white font-semibold py-3 px-12 mr-0 xl:mr-3 mb-4 uppercase tracking-wider shadow-md'>
              Let's Talk
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CTASection