/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"

 import HeaderNew from "../header/header-new"
import FooterUpdate from "../footer/footer-update"

 const Layout = ({ children }) => (
   <>
     <HeaderNew />
     <div>
       <main>
         {children}
        </main>
     </div>
     <FooterUpdate />
   </>
 )

 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }

 export default Layout
