import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

const SEOnew = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    query SEOnew {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: siteUrl
        }
      }
      defaultImage: file(relativePath: { eq: "backgrounds/fw-green-og.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }
  `)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
  } = data.site.siteMetadata

  const defaultImage = getSrc(data.defaultImage)

  const SEOnew = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={SEOnew.title} titleTemplate={titleTemplate}>
      <meta name="description" content={SEOnew.description} />
      <meta name="image" content={SEOnew.image} />

      {SEOnew.url && <meta property="og:url" content={SEOnew.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {SEOnew.title && <meta property="og:title" content={SEOnew.title} />}

      {SEOnew.description && (
        <meta property="og:description" content={SEOnew.description} />
      )}

      {SEOnew.image && <meta property="og:image" content={SEOnew.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {SEOnew.title && <meta name="twitter:title" content={SEOnew.title} />}

      {SEOnew.description && (
        <meta name="twitter:description" content={SEOnew.description} />
      )}

      {SEOnew.image && <meta name="twitter:image" content={SEOnew.image} />}

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://www.fastweb.dev",
            "name": "fastweb.dev",
            "contactPoint": {
              "@type": "ContactPoint",
              "email": "projects@fastweb.dev",
              "contactType": "Client Support"
            }
          }
        `}
      </script>
    </Helmet>
  )
}

export default SEOnew

SEOnew.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEOnew.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}