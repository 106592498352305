import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const FooterUpdate = () => {
  return (
    <footer className='bg-white font-body text-gray-900 text-center px-4 py-16'>
      <p className='text-base mb-8 leading-10'>
        <a href='https://www.facebook.com/Fastwebdev-103470724357364' target='_blank' rel='noopener noreferrer' className='p-2 hover:underline'>Facebook</a> | <a href='https://twitter.com/fastweb_dev' target='_blank' rel='noopener noreferrer' className='p-2 hover:underline'>Twitter</a> | <a href='https://www.linkedin.com/company/fastweb-dev/' target='_blank' rel='noopener noreferrer' className='p-2 hover:underline'>LinkedIn</a> | <a href='https://github.com/fastweb-dev-team' target='_blank' rel='noopener noreferrer' className='p-2 hover:underline'>Github</a> | <Link to="/blog" className='p-2 hover:underline'>Blog</Link> | <a href='https://www.gatsbysites.com/' target='_blank' rel='noopener noreferrer' className='p-2 hover:underline'>Gatsby Showcase</a>
      </p>

      <Link to="/">
        <div className="w-full max-w-lg flex flex-col items-center justify-center mx-auto mb-6">
          <div className="flex items-center">
            <StaticImage
              src="../../images/logos/fw-new-logo-green-for-white-background.png"
              width="200"
            />
          </div>
          <h2 className="text-gray-900 text-center mt-2">Website Design/Redesign & Development Services</h2>
        </div>
      </Link>

      <p className='text-sm mb-6'>© {new Date().getFullYear()} fastweb.dev</p>
    </footer>
  )
}

export default FooterUpdate